.send-message-container {
  padding: 20px !important;
  background-color: #f1f1f1;
  border-bottom-right-radius: 10px;
  width: 100%;
  border-bottom-left-radius: 10px !important;
}

.message-input-container {
  padding-left: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.message-input-container textarea {
  border: none !important;
  outline: none !important;
  width: 100% !important;
  min-height: 60px;
  font-size: 16px;
  border-radius: 10px;
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Hide the scrollbar */
}
.message-input-container input::placeholder {
  color: rgb(66, 91, 118) !important;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .send-message-container {
    width: 100%;
  }
}
