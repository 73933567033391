.header {
  margin-top: 20px !important;
  background-color: rgb(143, 135, 193) !important;
  padding: 15px !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.header img {
  border-radius: 50% !important;
  border: 2px solid #fff;
}

.header div {
  margin-left: 10px !important;
  padding-top: 5px !important;
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}

.header div h5 {
  margin: 0 !important;
  font-size: 20px;
  color: #fff;
}

.header button {
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  color: rgb(143, 135, 193);
  cursor: pointer;
}

.header button:hover {
  background-color: #eee;
}

@media only screen and (max-width: 768px) {
  .header {
    margin-top: 0px !important;
    width: 100%;
  }

  .header div h5 {
    font-size: 15px;
  }
}
