.bot-message {
  margin-top: 20px;
  background-color: rgb(143, 135, 193) !important;
  padding: 8px 16px !important;
  height: fit-content;
  border-radius: 0px 10px 10px 10px;
  color: white !important;
  width: fit-content;
  max-width: 100%;
  align-self: flex-start;
}

.user-message {
  margin-top: 20px;
  background-color: #d5e5f7 !important;
  padding: 8px 16px !important;
  height: fit-content;
  border-radius: 10px 0px 10px 10px;
  color: rgb(66, 91, 118) !important;
  width: fit-content;
  max-width: 100%;
  align-self: flex-end;
}

.typing {
  display: flex;
  justify-content: space-between;
}

.typing span {
  content: "";
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 10px;
  width: 10px;
  background: white;
  left: 0;
  top: 0;
  border-radius: 50%;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
