.message-window-container {
  padding: 15px !important;
  width: 100%;
  background-color: aliceblue;
  overflow-y: scroll !important ;
  height: 60vh !important;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .message-window-container {
    flex-grow: 1;
    width: 100%;
  }
}
