body {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: Helvetica, Arial, sans-serif;
}

.container {
  margin: auto;
  max-width: 800px;
  height: 80vh;
  justify-content: center !important;
  place-items: center;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100% !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
